.wrapper{
    width: 478px;
    height: 81px;

    background: #FFFFFF;
    border-radius: 15px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title{
    /* position: absolute; */
    width: 62px;
    height: 32px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;

    color: #0254A6;

    transform: rotate(-91.11deg);
}

.dataForm{
    display: flex;
    flex-direction: column;
    margin-right: 27px;
    gap:13px;
}

.coordInput{
    width: 101px;
    height: 20px;    
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 1000px;

    text-align: center;
}

.heightInput{
    width: 45px;
    height: 20px;
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 1000px;

    text-align: center;
}

.dataForm span{
    display: flex;
    justify-content: space-between;
    gap: 5px;
}

.dataForm span label{
    width: 53px;
    height: 21px;

    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    text-align: center;

    color: #0254A6;
}

.inputWrapper{
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 5px;
    border: 1px solid black;
}