.btnStyle {
    width: 136px;
    height: 46px;

    background: #0254A6;
    border-radius: 15px;

    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15.0661px;
    line-height: 18px;
    display: flex;
    align-items: center;

    color: #FFFFFF;

    display: flex;
    justify-content: center;
}