.wrapper{
    width: 155px;
    height: 80px;

    background: #FFFFFF;
    border-radius: 15px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    /* gap: 1px; */
}

.title{
    width: 74px;
    height: 42.99px;
    
    
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;

    color: #0154A6;

    transform: rotate(-90deg);

}

.dataForm{
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-right: 5px;
}

.dataForm span{
    display: flex;
    justify-content: space-between;
    gap:2.98px
}

.dataForm span input{
    width: 70.22px;
    height: 20px;

    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 1000px;

    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 21px;

    text-align: center;
}

.dataForm span label{


    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;


    text-align: center;

    color: #0154A6;
}