.btnOn{
    width: 150px;
    height: 37px;

    background: #001E47;
    mix-blend-mode: normal;
    border: 1px solid #001E47;
    border-radius: 5px;

    font-family: 'Actor', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    
    color: #FFFFFF;
}

.btnOff{
    width: 150px;
    height: 37px;
    
    font-family: 'Actor';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;

    background: #F2F2F2;
    mix-blend-mode: normal;
    border: 1px solid #001E47;
    border-radius: 5px;

    color: #001E47;
}

