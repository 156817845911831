.paramsCalc{
    /* display: grid; */
    width: 318px;
    height: 260px;
    display: grid;
    grid-template-columns: 150px 150px;
    grid-template-rows: 80px 80px 80px 77px;
    column-gap: 18px;
    row-gap: 12.07px; 


    /* margin-left: 20px; */
    /* margin-top: 7px; */
    /* margin-bottom: 13px; */
    /* border: 1px solid black; */
}

.calculator{
    width: 318px;
    height: 260px;

    display: grid;
    
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    column-gap: 8px;
    row-gap: 12.07px; 

    /* margin-left: 20px; */
    /* margin-top: 7px; */
    /* margin-bottom: 20px; */
    /* margin-right: 13px; */
}