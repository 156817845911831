.modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0);
}

.modal.active {
    transform: scale(1);
}



.modal__content {
    padding: 20px;
    border-radius: 12px;
    background-color: white;
}