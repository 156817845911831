.wrapper{
    height: 89px;
    background: #001E47;
    border-radius: 15px;
    height: 89px;
    display: flex;
    gap:10px;
}

.logo{
    border-radius: 15px;
}

.title{
    width: 370px;
    height: 51px;

    margin: auto 0;
    

    font-family: 'Alef', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;

    color: #FFFFFF;
}