.wrapper{
    width: auto;
    /* height: 80px; */
    display: flex;
    flex-direction: column;
    /* border: 1px solid black; */
    border-radius: 15px;
    background: #FFFFFF;
  }

.title {
    /* margin: 12.03px; */
    
    width: auto;
    height: auto;

    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 19.2513px;
    line-height: 25px;
    /* identical to box height */


    /* color: #0154A6; */
    color: 001E47;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    justify-content: center;

    /* border: 1px solid black; */

    text-align: center;
}
    
    /* Контейнер <div> - необходим для размещения выпадающего содержимого */
  .dropdown {
    position: relative;
    display: inline-block;
    /* width: 150px; */
    /* height: 48.93px; */
    background: #FFFFFF;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    /* background: rgb(209, 189, 189); */
  }
  

  
  .componentTitle{
    margin: 12.03px;
      
    width: 95px;
    height: 25px;
  
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    /* identical to box height */
  
  
    /* color: #0154A6; */
    color: 001E47;
  
  
  /* Inside auto layout */
  
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  
  
  
  
  .dropbtn {
      width: 19.25px;
      height: 19.25px;
  
      background-color: #FFFFFF;
      /* color: 4359CA; */
      color: rgba(67,89,202,100);
      /* padding: 16px; */
      font-size: 16px;
      border: none;
  
      margin-top: 14.91px;
      margin-right: 14.91px;
    }
    
  
    
    /* Выпадающее содержимое (скрыто по умолчанию) */
    .dropdownContent {
      display: none;
      position: absolute;
      top:10px;
      left: 119px;
      background-color: #f1f1f1;
      min-width: 130px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      z-index: 1;

      border-radius: 3.15528px;
    }

    /* .dropdownContent { */
      /* display: flex; */
      /* flex-direction: column; */
      /* align-items: flex-start; */
      /* padding: 11.8323px; */
      /* gap: 17.35px; */
/*  */
      /* position: relative; */
      /* width: 147.51.px; */
      /* height: 173.68px; */
/*  */
      /* background: #FFFFFF; */
      /* border-radius: 3.15528px; */
    /* } */
    
    /* Ссылки внутри выпадающего списка */
    .dropdownContent a {
      /* color: rgb(71, 68, 68); */
      color: rgba(106,106,106,100);
      padding: 6px 5px;
      text-decoration: none;
      display: block;
      border-radius: 5.15528px;
      align-items: center;

    }
    
    /* Изменение цвета выпадающих ссылок при наведении курсора */
    .dropdownContent a:hover {background-color: #ddd;}
    
    /* Показать выпадающее меню при наведении курсора */
    .dropdown:active .dropdownContent {display: block;}
    .dropdownContent:hover {display: block;}
    
    /* Изменение цвета фона кнопки раскрывающегося списка при отображении содержимого раскрывающегося списка */
    .dropdown:hover .dropbtn {background-color: rgb(187, 171, 171);} 