.wrapper{
    width: 150px;
    height: 57px;

    background: #FFFFFF;
    border-radius: 12.2293px;

    display: flex;
    flex-direction: column;

    gap:4px;

    justify-content: center;
    align-content: center;

    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13.0446px;
    line-height: 17px;
    /* identical to box height */

    /* text-align: center; */

    color: #0254A6;
}

.line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 140px;
    margin-left: 3px;

    /* border: 1px solid black; */
}

.input {
    display:flex;
    justify-content: space-between;
}

.input input {
    width: 52.99px;
    height: 14.68px;

    background: #FFFFFF;
    border: 0.815287px solid #000000;
    border-radius: 815.287px
}