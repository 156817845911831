.wrapper{
    width: 450px;
    height: 220px;
    /* margin-top: 6px; */
    
    border: 15px solid #FFFFFF;
    border-bottom: 27px solid #FFFFFF;
    /* border: 20px solid black; */
    border-radius: 15px;
}

.refreshButton {
    margin-top: 3px;
    background-color: #FFFFFF;
    color: #0254A6;
    border-radius: 5px;

    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    /* border: 1px solid black; */
    border: 1px solid #0254A6;
}