.wrapper {
    width: 193px;
    height: 128px;

    background: #FFFFFF;
    border-radius: 12.2293px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    gap: 4.96px;

    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13.0446px;
    line-height: 17px;
    /* identical to box height */

    /* text-align: center; */

    color: #73808d;
}

.dataForm {
    display: flex;
    flex-direction: column;
    gap:3px;
}

.line {
    /* width: 45px; */

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    /* border: 1px solid black; */

    margin-right: 4px;
    margin-left: 4px;
}

.input {
    width: 120px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    
    /* border: 1px solid black; */
}

.input input{
    width: 52.99px;
    height: 14.68px;
    background: #FFFFFF;
    border: 0.815287px solid #000000;
    border-radius: 815.287px;
}