.monoTable{
    margin-top: 15px;
    margin-bottom: 15px;
    border: 1px solid black;
    /* border-radius: 15px; */
}

.manyTable{
    margin-top: 15px;
    margin-bottom: 15px;
    border: 1px solid black;
    /* border-radius: 15px; */
}

.cellHat{
    background-color: rgb(160, 165, 240);
}
.cellData{
    background-color: rgb(217, 250, 217);
}

.rssiData{
    background-color: rgba(228, 128, 128, 0.514);
    border: 2px solid black;
}