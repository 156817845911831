.wrapper{
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13.0446px;
    line-height: 17px;

    color: #0254A6;

    width: 193px;
    height: 128px;

    background: #FFFFFF;
    border-radius: 12.2293px;

    display: flex;
    flex-direction: column;
    /* align-content: center; */
    justify-content: center;
    gap: 6px;

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.line{
    width: 170px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 8.71px;
    /* border: 1px solid black; */
}

.input{
    width: 90px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* border: 1px solid black; */
}

.input input{
    /* position: absolute; */
    width: 52.99px;
    height: 14.68px;

    background: #FFFFFF;
    border: 0.815287px solid #000000;
    border-radius: 815.287px;

    
    flex-direction: row;
}
