.outerWrapper {
    width: 318px;
    height: 77px;
    background: #FFFFFF;
    border-radius: 15.8522px;

    
}

.innerWrapper {
    display: flex;
    flex-direction: column;
    gap: 1.2px;
    /* padding: 6px; */
    /* padding-left: 10px; */
    /* padding-right: 6.28px; */
    /* padding-bottom: 1px; */
    padding: 4px;
    padding-right: 12.8px;
    padding-bottom: 9px;
    padding-left: 16px;
}

.innerWrapper span {
    display: flex;
    justify-content: space-between;

    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 10.4824px;
    line-height: 14px;
    /* identical to box height */

    text-align: center;

    color: #0154A6;
}

.innerWrapper span input {
    width: 50.21px;
    height: 13px;
    left: 340px;
    top: 402px;

    background: #FFFFFF;
    border: 0.873529px solid #000000;
    border-radius: 873.529px;

    text-align: center;
}