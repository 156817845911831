.wrapper{
    /* background-color: gray; */

    width: 870px;
    height: 600px;

    margin-left: auto;
    margin-right: auto;

    /*Debug*/
    /* background: #fae7e7; */
}

.inner-wrapper{
    width: 870px;
    height: 600px;

    margin-left: 65px;
    margin-top: 5px;
    padding-bottom: 15px;

    /*Debug*/
    /* background: rgb(202, 182, 182); */

    display: flex;
    flex-direction: column;
    gap: 12px
}

.switch-buttons{
    height: 37px;
    /* margin-left: 20px; */
    /* margin-right: 20px; */
    display: flex;
    gap: 20px;

    margin-bottom: 5px;
    margin-top: 5px;

    /* border-top: 1px solid #001E47; */
}

.main-container{
    width: 870px;
    /* height: 436px; */
    height: 480px;

    background: #F5F5F5;;
    border-radius: 10px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 3fr auto auto;
    justify-items: center;
    align-items: center;

    row-gap: 20px;
    column-gap: 40px;
    

}


.field-props-container{
    display: flex;
    gap: 13px;
    /*Перенос в параметры grid*/
    /* margin-left: 21px; */
    /* background: green; */
}

.station-coords-container{
    /*Перенос в параметры grid*/
    margin-right: 20px;
}

.submit-buttons-container{
    width: 320px;
    display:flex;
    justify-content: start;
    gap: 13px;
    /*Перенос в параметры grid*/
    margin-left: 21px;
    margin-top: 13px;
    margin-bottom: 16px;
    /* border: 1px solid black; */
}

.props-container-simple{
    /* display: grid; */
    width: 318px;
    height: 220px;
    display: grid;
    grid-template-columns: 150px 150px;
    grid-template-rows: 48.93px 48.93px 48.93px 77px;
    column-gap: 18px;
    row-gap: 12.07px; 


    margin-left: 20px;
    margin-top: 7px;
    margin-bottom: 13px;
    /* border: 1px solid black; */
}

.props-container-strong{
    width: 318px;
    height: 220px;

    display: grid;
    
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    column-gap: 8px;
    row-gap: 12.07px; 

    margin-left: 20px;
    margin-top: 7px;
    margin-bottom: 20px;
    margin-right: 13px;

}

.map-container{
    width: 450px;
    height: 220px;
    margin-top: 6px;

    

    border: 15px solid #FFFFFF;
    border-bottom: 27px solid #FFFFFF;
    /* border: 20px solid black; */
    border-radius: 15px;

}

.dog-nail-1{
    position: absolute;
    left:85px; 
    top: 109px;
}

.dog-nail-2{
    position: absolute;
    left:255px; 
    top:109px;
}
