.wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 6px;

    width: 148px;
    height: 80px;

    border-radius: 15px;
    background-color: #ffffff;
    /* border: solid 1px black; */

}

.title {
    width: 148px;
    height: 21px;
    /* left: 132px; */
    /* top: 166px; */

    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height */

    text-align: center;

    color: #0254A6;
}

.input{
    width: 138px;
    height: 22px;
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 1000px;
    margin-top: 3px;
    margin-left: 1.5px;
    margin-right: 1.5px;
    margin-bottom: 3px;
    text-align: center;
    /* margin-right: 1px; */
}